.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  background-color: #f5f5f5;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.login-container form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.login-container form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login-container form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container form button:hover {
  background-color: #0056b3;
}

/* Styling für den Google Login Container */
.google-login-container {
  padding: 10px;  /* Abstand zwischen dem Formular und Google-Login */
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 300px; /* Gleiche Breite wie das Login-Formular */
}

/* Styling für den Google Login Button */
.google-login-container .google-login-button {
  width: 100%; /* Volle Breite innerhalb des Containers */
  display: flex;
  justify-content: center;
}

/* Styling für den Sign-In Link */
.sign-in-link-container {
  margin-top: 20px; /* Abstand zwischen dem Google Login und dem Sign-In Link */
  
  text-align: center;
}

.sign-in-link-container a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.sign-in-link-container a:hover {
  text-decoration: underline;
}


body {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  box-sizing: border-box;
}

/* Stellt sicher, dass der Container den gesamten verfügbaren Platz einnimmt */
.login-container {
  min-height: calc(100vh - 20px); /* Berechnet die Höhe unter Berücksichtigung des Paddings */
  margin-bottom: 0; /* Entfernt den unteren Rand */
  padding-bottom: 0; /* Entfernt das Padding am unteren Rand */
}