/* AddArticle.css */

/* Container für die Formulare */
.article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Formular-Stile */
  .article-form {
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  .article-form input[type="text"],
  .article-form textarea,
  .article-form input[type="file"] {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .article-form textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  .article-form button {
    background-color: #003366;
    color: #ffffff;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .article-form button:hover {
    background-color: #0056b3;
  }
  
  /* Popup-Nachrichten */
  .popup-message {
    width: 100%;
    max-width: 600px;
    margin: 1rem auto;
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
  }
  
  .popup-message.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .popup-message.error {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .article-form {
      padding: 1rem;
    }
  
    .article-form textarea {
      min-height: 100px;
    }
  
    .article-form button {
      padding: 0.6rem 1rem;
    }
  }
  