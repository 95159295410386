/* src/components/SignIn.css */
.signin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 65vh;
    background-color: #f5f5f5;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.signin-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.signin-container form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.signin-container form input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none; /* Entfernt den Standard-Outline */
}

.signin-container form input.error {
    border-color: #e74c3c; /* Rote Grenze für Fehler */
}


.signin-container form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signin-container form button:hover {
    background-color: #0056b3;
}

.feedback {
    margin-top: 10px;
    font-size: 14px;
}

.feedback.error {
    color: #e74c3c; /* Rot für Fehler */
}

.feedback.success {
    color: #28a745; /* Grün für Erfolg */
}
