.article-container {
  padding: 2rem;
  max-width: 100%;
  margin: 0 auto;
}

.article-container h1 {
  margin: 0 0 1rem;
  color: #003366;
}

.article-container p {
  line-height: 1.6;
}

.article-thumbnail {
  display: block;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.back-link {
  display: inline-block;
  margin-bottom: 1rem;
  color: white;
  background-color: #003366;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-top: 5%;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: #005bb5;
  text-decoration: none;
}



.comment-section {
  width: 100%; /* Gleiche maximale Breite wie der Artikel */
  margin: 0 auto; /* Zentrieren der Kommentar-Sektion */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.comment-section h2 {
  margin-bottom: 1rem;
  color: #003366;
}

.comment-form {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.comment-form textarea {
  flex: 1;
  height: 50px;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  margin-right: 0.5rem;
  resize: none;
}

.comment-form button {
  padding: 0.5rem 1rem;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.comment-form button:hover {
  background-color: #005bb5;
}

.comments-list {
  margin-top: 1rem;
}

.comment {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ccc;
  position: relative; /* Position für absolute Positionierung der Delete-Button */
}

.comment strong {
  color: #003366;
}

/* Stil für Benutzernamen des Admins */
.comment .admin-username {
  color: purple; /* Lila Farbe für Admin-Benutzernamen */
}

.login-link {
  color: #003366;
}

.login-link:hover {
  text-decoration: underline;
}

/* Stil für die Löschschaltfläche */
.delete-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.delete-button:hover {
  color: darkred;
}
