/* src/App.css */

.App {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Mindesthöhe des gesamten Viewports */
  position: relative; /* Relativer Positionierungs-Kontext für den Footer */
  display: flex;
  flex-direction: column; /* Hauptinhalt in Spaltenrichtung anordnen */
}

main {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1; /* Flexibles Hauptinhalt-Bereich, der den verbleibenden Platz einnimmt */
}

.article-form {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.article-form input,
.article-form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.article-form button {
  background-color: #003366;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.article-form button:hover {
  background-color: #005bb5;
}

footer {
  background-color: #003366;
  color: white;
  text-align: center;
  padding: 0rem;
  width: 100%;
  position: relative;
  bottom: 0;
}
footer a{
  color:inherit;
  text-decoration: none;

}
